.productWithImage__content {
  display: grid;
  width: 100vw;
  height: 160px;
  margin: 200px 0;
  grid-template-rows: 1fr 0.3fr;
  z-index: 99;

  .block__black {
    grid-area: block;
    background-color: black;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 98;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    h3 {
      font-size: 28px;
      text-transform: uppercase;
      color: white;
      padding: 20px 10px;
      font-weight: 100;
    }
  }
  .content__block {
    grid-area: title;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 100;
    font-size: 28px;
    color: white;

    a {
      font-size: 10px;
      color: white;
      font-weight: 100;
      width: 150px;
      text-decoration: none;
      position: relative;
      justify-self: flex-end;
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-width: 80px;
      img {
        width: 30px;
      }
    }
    .img__content {
      width: 130px;
      position: absolute;
      z-index: 99;
    }
  }
  .text__content {
    grid-area: text;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px 60px;
    color: white;
    font-weight: 300;
    font-size: 20px;

    & strong {
      display: contents;
    }
  }
}

.productWithImage__content_left {
  grid-template-columns: 7% 60% 10% 25%;
  grid-template-areas:
    "block title . ."
    ". text text .";
  .block__black {
    transform: rotate(180deg);
  }
  .img__content {
    left: 4vw;
  }
  .block__black h3 {
    margin-right: 10px;
  }
  .content__block {
    padding: 30px 30px 30px 80px;
  }
}

.productWithImage__content_right {
  grid-template-columns: 23% 10% 60% 9%;
  grid-template-areas:
    ". . title block"
    ". text text .";
  .img__content {
    right: 4vw;
  }
  .block__black h3 {
    margin-left: 40px;
  }
  .content__block {
    padding: 30px 80px 30px 30px;
  }
}

.grid__container_background {
  background-size: cover;
  height: auto;
  width: 100vw;
  padding: 10vh 0;
}

@media screen and (max-width: 1199px) {
  .productWithImage__content {
    margin: 300px 0;
  }
  .productWithImage__content_left {
    grid-template-columns: 10% 70% 10% 10%;

    .img__content {
      left: 6vw;
    }
    .block__black h3 {
      margin-right: 10px;
    }
  }

  .productWithImage__content_right {
    grid-template-columns: 9% 10% 70% 11%;
    .img__content {
      right: 8vw;
    }
    .block__black h3 {
      margin-left: 40px;
    }
  }
}

@media screen and (max-width: 789px) {
  .grid__container_background {
    background-size: contain;
  }
  .productWithImage__content {
    margin: 20px 0;
    height: auto;

    .text__content {
      padding: 10px 20px;
    }
  }
  .productWithImage__content_left {
    grid-template-columns: 20% 75% auto auto;

    .img__content {
      left: 12vw;
    }
  }

  .productWithImage__content_right {
    grid-template-columns: auto auto 75% 20%;
    .img__content {
      right: 13vw;
    }
  }

  .content__block {
    flex-direction: column;
  }
}
