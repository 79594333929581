.scroll__to_top {
  width: 50px;
  height: 50px;
  border-radius: 40px;
  background-color: transparent;
  border: 1px solid black;
  position: fixed;
  bottom: 2vh;
  right: 3vw;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}
