body {
    margin: 0;
}

.maintenance {
    width: 100vw;
    height: 100vh;

}

.bg-img {

    background-image: url('https://maestabackend.xdesigners.it/wp-content/uploads/2021/02/CIMG6497.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100vw;
    height: 100vh;
    z-index:0;
}
.bg-overlay {
   
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index:1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
h1 {
    font-size: 2.5rem;
    padding: 20px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 1rem;
    z-index: 2;
    text-align: center;
}