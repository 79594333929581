.content__page {
  margin-top: 0 !important;
}

.content__page__first {
  grid-area: grid;
  margin: 0vh 0 0 0 !important;

  .content__item__first__right {
    width: 100vw;
    grid-template-columns: 25% 65% 10%;
    margin-top: 0;
  }

  .content__block__inner-page {
    grid-area: 1 / 1 / 3 / 2;
    position: relative;

    .text__intro {
      width: 50%;
      position: absolute;
      top: 40%;
      left: 16%;

      p {
        margin: 0;
        font-weight: 100;
        font-size: 40px;
      }
    }

    img {
      width: 50%;
      position: absolute;
      bottom: 15%;
      left: 0;
    }
  }
  .content__item-imgwrap {
    grid-area: 1 / 2 / 1 / 2;
    height: 80vh;
  }
  .content__item-path {
    grid-area: 1 / 2 / 2 / 4;
    display: flex;
    justify-content: flex-end;
    svg {
      margin-top: 15vh;
    }
  }
}

.title__container {
  min-width: 12vw;
  height: 70px;
  background-color: rgb(0, 0, 0);
  position: absolute;
  right: 0;
  top: 25vh;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 9999;

  h1.title {
    color: white;
    font-size: 28px;
    padding-right: 10px;
    font-weight: 300;
    margin: 0;
    text-transform: uppercase;
  }
}

.buttons__products__header {
  display: grid;
  position: absolute;
  bottom: 2vh;
  left: 0;
  width: 100vw;
  margin: 0;
  padding: 0 5vw;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  div.button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    a {
      background-color: black;
      padding: 20px 30px;
      color: white;
      font-size: 24px;
      min-width: 200px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      width: auto;
      text-decoration: none;
    }
  }

  @for $i from 1 to 5 {
    div.button {
      grid-column: #{$i} -1 / #{$i};
    }
  }
}

@media only screen and (max-width: 1000px) {
  .buttons__products__header {
    grid-template-columns: 1fr 1fr;

    @for $i from 1 to 3 {
      div.button {
        grid-column: #{$i} -1 / #{$i};
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .buttons__products__header {
    bottom: -22vh;
    grid-template-columns: 1fr;
    div.button {
      grid-column: 1;
      a {
        width: 80vw;
      }
    }
  }
}

@media only screen and (min-width: 581px) and (max-width: 781px) {
  .content__page__first {
    .content__item__first__right {
      .content__block__inner-page {
        grid-area: 1 / 2 / 3 / 3;
        .text__intro {
          width: 100%;
          top: 50%;
          left: 5%;
        }
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .content__page__first {
    .content__item__first__right {
      grid-template-columns: 40% 60%;
      margin-top: 0;

      .content__block__inner-page {
        grid-area: 2 / 1 / 2 / 3;
        .text__intro {
          width: 100%;
          top: -40%;
          left: 5%;
          font-size: 26px;
        }
      }
      .content__item-imgwrap {
        grid-area: 2 / 1 / 2 / 3;
      }
    }
  }
}
