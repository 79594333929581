*,
*::after,
*::before {
  box-sizing: border-box;
}

.page {
  display: grid;
  margin: 0 auto;
  grid-template-columns: 100%;
  grid-template-areas: "header" "meta" "grid";
  will-change: transform;
}

.content {
  grid-area: grid;
  margin: 25vh 0 10vh;
}

.content--full {
  width: 100vw;
  justify-self: center;
}

.content--full-height {
  height: 100vh;
  justify-self: center;
}

.content__item {
  --imgwidth: calc(var(--imgwidthmax) * var(--aspect-ratio));
  width: var(--imgwidth);
  max-width: 100%;
  position: relative;
  will-change: transform;
  margin-bottom: 15vh;
  margin-top: 15vh;
  display: grid;
  width: 100vw;
}
.content__item svg {
  width: auto;
}
.content__item .content__item-path {
  position: relative;
  font-size: var(--size-title);
  padding: 0;
  margin: calc(var(--size-title) * -1 / 2) 0 0 0;
  align-self: start;
  line-height: 1;
  font-family: var(--font-title);
  font-weight: var(--font-weight-title);
  color: var(--color-title);
  will-change: transform;
  mix-blend-mode: var(--blendmode-title);
  z-index: -1;
}
.content__item .content__item-imgwrap {
  position: relative;
  --imgwidth: 100%;
  margin: 0 auto;
  overflow: hidden;
  width: var(--imgwidth);
  padding-bottom: calc(var(--imgwidth) / (var(--aspect-ratio)));
  will-change: transform;
}
.content__item .content__item-imgwrap .content__item-img {
  --overflow: 40px;
  height: calc(100% + (2 * var(--overflow)));
  top: calc(-1 * var(--overflow));
  width: 100%;
  position: absolute;
  background-size: cover;
  object-fit: cover;
  object-position: 50% 0;
  background-position: 50% 0%;
  will-change: transform;
  opacity: 1;
}
.content__item .content__item-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  height: 50vh;
}
.content__item .content__item-text .title {
  background-color: black;
  display: flex;
  align-items: center;
  padding: 15px;
  height: 60px;
  width: 50%;
  right: 0;
}
.content__item .content__item-text .title h3 {
  font-size: 22px;
  font-weight: 100;
  color: white;
  margin: 0;
  text-transform: uppercase;
}
.content__item .content__item-text .description {
  display: flex;
  flex-direction: column;
  padding: 25px;
  color: black;
  font-size: 16px;
  font-weight: 100;
  width: 80%;
}
.content__item .content__item-text .description p {
  margin: 0;
}
.content__item .content__item-text .continue {
  font-weight: 300;
  font-size: 26px;
  width: 40%;
  text-decoration: none;
  position: relative;
  color: black;
}
.content__item .content__item-text .continue::after {
  content: "";
  display: block;
  position: absolute;
  width: 95%;
  height: 1px;
  background: black;
  bottom: -5px;
}

.content__first {
  grid-area: grid;
  margin: 15vh 0 0 0;
}
.content__first .content__item__first__right {
  width: 100vw;
  grid-template-columns: 30% 60% 10%;
}
.content__first .content__block__inner-page {
  grid-area: 1/1/3/2;
  position: relative;
}
.content__first .content__block__inner-page .text__intro {
  font-weight: 100;
  font-size: 40px;
  width: 50%;
  position: absolute;
  top: 20%;
  left: 16%;
}
.content__first .content__block__inner-page img {
  width: 50%;
  position: absolute;
  bottom: 15%;
  left: 0;
}
.content__first .content__item-imgwrap {
  grid-area: 1/2/3/3;
  height: 700px;
}
.content__first .content__item-path {
  grid-area: 1/2/1/3;
  right: -20vw;
  top: 5vh;
}

.content__item__left {
  grid-template-columns: 10% 50% 40%;
}
.content__item__left .content__item-imgwrap {
  grid-area: 1/2/3/3;
  height: 600px;
}
.content__item__left .content__item-path {
  grid-area: 1/1/1/3;
  left: -5vw;
}
.content__item__left .content__item-text {
  grid-area: 1/4/4/3;
  align-items: flex-end;
}
.content__item__left .content__item-text .title {
  justify-content: flex-start;
}
.content__item__left .content__item-text .description {
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right;
}
.content__item__left .content__item-text .continue {
  text-align: left;
}
.content__item__left .content__item-text .continue::after {
  right: -10%;
}

.content__item__right {
  grid-template-columns: 40% 50% 10%;
  margin-left: auto;
}
.content__item__right .content__item-imgwrap {
  grid-area: 1/2/3/3;
  height: 600px;
}
.content__item__right .content__item-path {
  grid-area: 3/4/1/2;
  right: -20vw;
}
.content__item__right .content__item-text {
  grid-area: 1/1/2/2;
  align-items: flex-start;
}
.content__item__right .content__item-text .title {
  justify-content: flex-end;
}
.content__item__right .content__item-text .description {
  justify-content: flex-end;
  text-align: left;
}
.content__item__right .content__item-text .continue {
  text-align: right;
}
.content__item__right .content__item-text .continue::after {
  right: 10%;
}

@media only screen and (min-width: 780px) and (max-width: 1600px) {
  .content__item .content__item-imgwrap {
    height: 500px;
  }

  .content__first .content__block__inner-page img {
    width: 50%;
    position: absolute;
    bottom: 15%;
    left: 0;
  }
  .content__first .content__item-path {
    right: -5vw;
  }

  .content__item__left .content__item-path {
    grid-area: 1/1/1/3;
    left: -50vw;
  }
}
@media only screen and (max-width: 780px) {
  .content__item svg {
    height: 50vh;
  }
  .content__item .content__item-imgwrap {
    height: 500px;
  }
  .content__item .content__item-imgwrap .content__item-img {
    height: calc(50% + (2 * var(--overflow)));
    top: calc(-1 * var(--overflow));
  }
  .content__item .content__item-text {
    height: 50vh;
  }
  .content__item .content__item-text .title {
    height: 60px;
    width: 50%;
  }
  .content__item .content__item-text .title h3 {
    font-size: 18px;
  }
  .content__item .content__item-text .description {
    padding: 25px;
    font-size: 16px;
    width: 80%;
  }
  .content__item .content__item-text .continue {
    font-size: 26px;
    width: 40%;
  }
  .content__item .content__item-text .continue::after {
    bottom: -5px;
    right: 0;
  }

  .content__first {
    margin: 25vh 0 0 0;
  }
  .content__first .content__item__first__right {
    grid-template-columns: 15% 80% 5%;
  }
  .content__first .content__block__inner-page {
    grid-area: 2/2/4/4;
    top: -10vh;
  }
  .content__first .content__block__inner-page .text__intro {
    font-size: 30px;
    top: 0;
    left: 0;
  }
  .content__first .content__block__inner-page img {
    bottom: 15vh;
  }
  .content__first .content__item-imgwrap {
    grid-area: 1/2/3/3;
  }
  .content__first .content__item-path {
    grid-area: 1/2/2/2;
    right: -5vw;
    top: -5vh;
  }

  .content__item__left {
    grid-template-columns: 5% 80% 15%;
  }
  .content__item__left .content__item-imgwrap {
    grid-area: 1/2/3/3;
  }
  .content__item__left .content__item-path {
    grid-area: 1/1/1/3;
    left: -85vw;
  }
  .content__item__left .content__item-text {
    grid-area: 2/2/2/4;
  }

  .content__item__right {
    grid-template-columns: 15% 80% 5%;
    margin-left: auto;
  }
  .content__item__right .content__item-imgwrap {
    grid-area: 1/2/3/3;
  }
  .content__item__right .content__item-path {
    grid-area: 3/4/1/2;
    right: -15vw;
  }
  .content__item__right .content__item-text {
    grid-area: 4/3/4/1;
  }
}