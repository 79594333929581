.product__content {
  display: grid;
  width: 100vw;
  height: 160px;
  margin: 20px 0;
  grid-template-columns: 10% 60% 10% 20%;
  grid-template-rows: 1fr 0.3fr;
  grid-template-areas:
    "block title . ."
    ". text text .";

  .block__black {
    grid-area: block;
    background-color: black;
  }
  .title__block {
    grid-area: title;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    text-transform: uppercase;
    font-weight: 100;
    font-size: 28px;
    color: white;

    a {
      font-size: 10px;
      color: white;
      font-weight: 100;
      text-decoration: none;
      position: relative;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      img {
        width: 30px;
      }
    }
  }
  .text__content {
    grid-area: text;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    color: black;
    font-weight: 300;
    font-size: 18px;
  }
}

@media screen and (max-width: 589px) {
  .product__content {
    width: 100vw;
    height: 160px;
    margin: 50px 0;
    grid-template-columns: 10% 60% 20% 10%;
    grid-template-rows: 1fr 0.3fr;
    grid-template-areas:
      "block title title ."
      ". text text .";

    .title__block {
      padding: 0 15px;
      font-size: 22px;
    }
  }
}
