

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600;700&display=swap");


body {
  font-family: "Oswald", sans-serif !important;
}
p {
  font-size: 20px;
  font-weight: 100;
}

.cursor {
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  position: absolute;
  z-index: 999999;
  pointer-events: none;
  transition: background 50ms ease-in-out;
}
.hover_white {
  animation: cursorAnimHoverIn 0.3s forwards;
  animation-timing-function: ease-out;
  border: 0.5px solid white;
  background-color: rgba(0, 0, 0, 0);
  transform: scale(1);
}
.hover_black {
  animation: cursorAnimHoverIn 0.3s forwards;
  animation-timing-function: ease-out;
  border: 0.5px solid black;
  background-color: rgba(0, 0, 0, 0);
  transform: scale(1);
}
@keyframes cursorAnimHoverIn {
  to {
    transform: scale(1.5);
  }
}
@keyframes cursorAnimHoveOut {
  to {
    transform: scale(1);
  }
}

.expand {
  background-color: rgba(255, 0, 0, 0.2);
  animation: cursorAnim 0.5s forwards;
}
@keyframes cursorAnim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2.5);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

// ANIMATION TRANSITION PAGE

.bg-black {
  background-color: black;
}
.bg-black-out {
  background-color: black;
}
.w-full {
  width: 100vw;
}
.absolute {
  position: fixed;
  left: 0;
  bottom: 0;
}
.z-50 {
  z-index: 9999;
}
