.productWithTable__content {
  display: grid;
  width: 100vw;
  height: auto;
  margin: 200px 0;
  grid-template-rows: 1fr 0.3fr;
  z-index: 99;

  .block__black {
    grid-area: block;
    background-color: black;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 98;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    h3 {
      font-size: 28px;
      text-transform: uppercase;
      color: white;
      padding: 20px 10px;
      font-weight: 100;
    }
  }
  .content__block {
    grid-area: title;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 100;
    font-size: 28px;
    color: black;

    a {
      font-size: 10px;
      color: black;
      font-weight: 100;
      width: 150px;
      text-decoration: none;
      position: relative;
      justify-self: flex-end;
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-width: 80px;
      img {
        width: 30px;
      }
    }
    .img__content {
      width: 130px;
      position: absolute;
      z-index: 99;
    }
  }
}
  .text__content {
    grid-area: text;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    color: black;
    font-weight: 300;
    font-size: 20px;

    & .table__header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 100;
      font-size: 18px;
      color: black;
      margin-bottom: 5px;
     
      gap: 5px;
      row-gap: 5px;
      
      & span {
        text-align: center;
        font-weight: 500;
        text-transform: uppercase;
        width: 100%;
        padding: 5px 10px;
        background-color: rgb(168, 168, 168);
      }
    }
    & .table__body {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 100;
      font-size: 18px;
      color: black;
      
      margin-bottom: 5px;
      gap: 5px;
      row-gap: 5px;
      
      & span {
        text-align: center;
        width: 100%;
        padding: 5px 10px;
        background-color: rgb(240, 240, 240);
        align-self: stretch;
      }
    }

    & strong {
      display: contents;
    }
  }


.productWithTable__content_left {
  grid-template-columns: 7% 60% 10% 25%;
  grid-template-rows: auto 100%;
  grid-template-areas:
    "block title . ."
    ". text text .";
  .block__black {
    transform: rotate(180deg);
  }
  .img__content {
    left: 4vw;
  }
  .block__black h3 {
    margin-right: 10px;
  }
  .content__block {
    padding: 30px 30px 30px 80px;

    
  }
  & .text__content {
    margin-top: -10%;
    padding-left: 12%;
  }
  
}

.productWithTable__content_right {
  grid-template-columns: 23% 10% 60% 9%;
  grid-template-areas:
    ". . title block"
    ". text text .";
  .img__content {
    right: 4vw;
  }
  .block__black h3 {
    margin-left: 40px;
  }
  .content__block {
    padding: 30px 80px 30px 30px;

  }
  & .text__content {
    margin-top: -10%;
    padding-right: 12%;
  }
}

.grid__container_background {
  background-size: cover;
  height: auto;
  width: 100vw;
  padding: 10vh 0;
}

@media screen and (max-width: 1199px) {
  .productWithTable__content {
    margin: 300px 0;
  }
  .productWithTable__content_left {
    grid-template-columns: 10% 70% 10% 10%;

    .img__content {
      left: 6vw;
    }
    .block__black h3 {
      margin-right: 10px;
    }
  }

  .productWithTable__content_right {
    grid-template-columns: 9% 10% 70% 11%;
    .img__content {
      right: 8vw;
    }
    .block__black h3 {
      margin-left: 40px;
    }
  }
}

@media screen and (max-width: 789px) {
  .grid__container_background {
    background-size: contain;
  }
  .productWithTable__content {
    margin: 20px 0;
    height: auto;

    .text__content {
      padding: 10px 20px;
    }
  }
  .productWithTable__content_left {
    grid-template-columns: 100% 100%;
    grid-template-rows: auto 100%;
    grid-template-areas:
    "block title "
    "text .";
    position: relative;


    .block__black {
      width: 25%;
      height: 180px;
    }

    .img__content {
      top: -10px;
      left: 20vw;
      width: auto !important;
      height: 200px;
    }

    .text__content {
      margin-top: 0px;
      margin-left: 0;
      display: block;
    }
  }

  .productWithTable__content_right {
    grid-template-columns: 100% 100%;
    grid-template-rows: auto 100%;
    grid-template-areas:
    "block title"
    "text .";
    position: relative;

    .block__black {
      width: 25%;
      height: 180px;
    }

    .img__content {
      top: -10px;
      left: 20vw;
      width: auto !important;
      height: 200px;
    }
    .text__content {
      margin-top: 0px;
      margin-left: 0;
    }
  }

  .content__block {
    flex-direction: column;
  }
}
