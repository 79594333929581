.banner-privacy {
    background-color: #f5f5f5;
    padding: 20px 0;
    text-align: center;
    height: 60px;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-around;

    p {
        padding: 0 10px;
    }
}

.buttonCookies {
    background-color: black;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
}


@media screen and (max-width: 768px) {
    .banner-privacy {
        height: 50px;
        
        p {
            font-size: 12px;
            padding: 0 10px;
        }
    }
    .buttonCookies {
        background-color: black;
        font-size: 12px;
        padding: 6px 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        cursor: pointer;
    }
}
    
