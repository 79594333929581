.contacts__grid__container {
  display: grid;
  grid-template-areas:
    "form person1"
    "form person2"
    "form person3";
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  margin: 10vh 0;
  padding: 0 5vw;
}

.form {
  grid-area: form;
  padding: 40px;
  h2 {
    font-weight: 300;
    font-size: 36px;
    width: 100%;
    text-decoration: none;
    position: relative;
    color: black;
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 95%;
      height: 1px;
      background: black;
      bottom: -5px;
      left: -90%;
    }
  }

  input.disabled {
    pointer-events: none;
    background-color: lightgray !important;
  }

  label {
    font-weight: 100;
    text-transform: lowercase;
    width: 100%;

    input,
    textarea {
      width: 100%;
      padding: 15px;
      margin: 5px 0 35px 0;
      border-radius: 0;
      border: 1px solid black;
      transition: 0.25s all ease-out;

      &:focus {
        border-color: red;
        outline: red;
      }
    }

    textarea {
      min-height: 180px;
    }
  }
  input[type="submit"] {
    background-color: black;
    padding: 20px 60px;
    border: 0;
    color: white;
    text-transform: lowercase;
  }
}

.person1 {
  grid-area: person1;
  display: flex;
  align-items: center;
  flex-direction: row;

  img {
    padding: 20px;
    width: 250px;
    object-fit: cover;
    border-radius: 250px;
  }
  p {
    padding-right: 50px;
  }
}
.person2 {
  grid-area: person2;
  display: flex;
  align-items: center;
  flex-direction: row;

  img {
    padding: 20px;
    width: 250px;
    object-fit: cover;
    border-radius: 250px;
  }
  p {
    padding-right: 50px;
  }
}
.person3 {
  grid-area: person3;
  display: flex;
  align-items: center;
  flex-direction: row;

  img {
    padding: 20px;
    width: 250px;
    object-fit: cover;
    border-radius: 250px;
  }
  p {
    padding-right: 50px;
  }
}

@media screen and (min-width: 591px) and (max-width: 991px) {
  .person1,
  .person3 {
    flex-direction: column;
  }
  .person2 {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 590px) {
  .contacts__grid__container {
    grid-template-areas:
      "person1"
      "person2"
      "person3"
      "form";
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto;
  }

  .person1,
  .person3 {
    flex-direction: column;
  }
  .person2 {
    flex-direction: column-reverse;
  }
}
