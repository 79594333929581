.menu {
  width: 12vw;
  height: 70px;
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 0;
  top: 25vh;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 9999;
  .hamburger {
    width: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    span {
      width: 100%;
      height: 1px;
      background-color: white;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 1px;
        background: black;
        bottom: 0;
        left: 0;
        transition: all 0.5s ease-in-out;
        transition-delay: 0.2s;
      }
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 1px;
        background: red;
        bottom: 0;
        left: 0;
        transition: all 0.5s ease-in-out;
        transition-delay: 0.6s;
      }
    }
    span:nth-child(3) {
      width: 50%;
    }

    &:hover span:before {
      width: 100%;
    }
    &:hover span:after {
      width: 100%;
    }
  }
  .text__menu {
    color: white;
    font-size: 28px;
    padding-left: 10px;
    font-weight: 300;

    &:hover ~ .hamburger span:before {
      width: 100%;
    }
    &:hover ~ .hamburger span:after {
      width: 100%;
    }
  }
}

.container__menu {
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  width: 50vw;
  height: 100vh;
  left: -50vw;
  top: 0;

  ul {
    padding-left: 0;
    list-style-type: none;
    position: absolute;
    top: 25vh;
    left: 40%;
    li {
      padding-bottom: 30px;
      a {
        color: white;
        font-size: 34px;
        text-transform: uppercase;
        text-decoration: none;
      }
      span {
        width: 100%;
        float: left;
        font-size: 16px;
        margin-top: -10px;
        color: lightgrey;
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .menu {
    width: 40vw;
    top: 5vh;
  }
  .container__menu {
    width: 95vw;
    left: -100vh;

    ul {
      padding-left: 0;
      list-style-type: none;
      position: absolute;
      top: 20vh;
      left: 20%;
      li a {
        font-size: 24px;
      }
    }
  }
}
