// PARALLAX
.simpleParallax {
  width: 100vw;
}
.parallax__container {
  position: relative;
  margin: 100px 0;
  width: 100vw;
  overflow-y: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 0;
    overflow: hidden;

    img {
      min-height: 100vh;
      width: 100vw;
    }
  }

  .foreground {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    display: grid;
  }
}

.parrallax_circle {
  margin: 45vh 0;
}

// CIRCLE

.circle__black {
  position: absolute;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -55vh;
  z-index: -1;

  svg {
    padding: 10%;
  }

  .volti__container {
    position: absolute;
    top: 15vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 15% 1fr 1fr 1fr 15%;

    .volti {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      img {
        width: 50%;
        border-radius: 200px;
      }
    }
    .volti:nth-child(2) img {
      margin-top: 60px;
    }
    @for $b from 1 to 4 {
      .volti:nth-child(#{$b}) {
        grid-column: #{$b + 1} / #{$b + 2};
      }
    }
  }
}

// GRID ITEM RIGHT
.content__item__parallax__right {
  display: grid;
  width: 100vw;
  height: auto;
  grid-template-columns: 30% 70%;
  grid-template-areas: ". text";
  z-index: 9;
  position: absolute;
  top: 10%;

  .content__item__parallax-text {
    grid-area: text;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .title {
      background-color: black;
      display: flex;
      align-items: center;
      padding: 15px;
      height: 60px;
      width: 30%;
      right: 0;

      h3 {
        font-size: 22px;
        color: white;
        margin: 0;
        font-weight: 100;
        text-transform: uppercase;
      }
    }
    .description {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;
      padding: 25px;
      color: black;
      font-size: 22px;
      font-weight: 100;
      width: 80%;
      margin-right: 10%;
      p {
        margin-bottom: 0;
      }
    }
    .continue {
      font-weight: 300;
      font-size: 26px;
      width: 30%;
      text-decoration: none;
      position: relative;
      color: black;
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 95%;
        height: 1px;
        background: black;
        bottom: -5px;
        right: 0;
      }
    }
  }
}

// BIG DEVICE / TABLET
@media only screen and (max-width: 768px) {
  .parrallax_circle {
    margin: 45vh 0 15vh 0;
  }
  .content__item__parallax__right {
    grid-template-columns: 5% 95%;
    top: 10%;
    .content__item__parallax-text .description {
      padding: 10px;
      font-size: 22px;
    }
  }
  .circle__black .volti__container {
    grid-template-columns: 2% 1fr 1fr 1fr 2%;

    .volti img {
      width: 95%;
    }
  }
}

// SMALL DEVICE / SMARTPHONE
@media only screen and (max-width: 568px) {
  .content__item__parallax__right .content__item__parallax-text .title {
    width: 80%;
  }
}
